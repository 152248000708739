import { init, register } from 'svelte-i18n';

register('en', () => import('./en'));
register('es', () => import('./es'));

export const defaultLocale = 'es';

init({
  fallbackLocale: defaultLocale,
  initialLocale: defaultLocale,
});

